'use strict';

import React from 'react';
import Helmet from 'react-helmet';
import { COLOR, BREAKPOINT } from '@latitude/core/utils/constants';
import styled from 'styled-components';
import { Lframe } from '@latitude/lframe';
import { FeaturesSlider } from '@latitude/features-slider';
import { List, ListItem } from '@latitude/list';
import HowToApply from '@latitude/how-to-apply';
import Section from '@latitude/section';

import HorizontalRule from '@/components/HorizontalRule/HorizontalRule';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import Layout from '@/components/layout';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import imageHero from './images/how-we-protect-you-background.png';
import imageConfettiLeft from './images/confetti-background-left.png';
import imageConfettiRight from './images/confetti-background-right.png';

import PageData from './data/how-we-protect-you.json';
import ContactingLatitude from './_contacting-latitude';

const SITE_URL = 'https://www.latitudefinancial.com.au';

const HowWeProtectYou = props => {
  return (
    <Layout location={props.location} title={PageData.title}>
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`${SITE_URL}${props.location.pathname}`}
          />
          <title>{PageData.meta.title}</title>
          <meta name="description" content={PageData.meta.description} />
        </Helmet>

        <Lframe bgImage={imageHero} />
        <HeroBranded
          className="bg-lightest"
          title={PageData.hero.title}
          text={PageData.hero.text}
          breadcrumbs={PageData.hero.breadcrumbs}
          css={`
            @media (min-width: ${BREAKPOINT.MD}) {
              .HeroTitle {
                padding-top: 80px;
              }
              .HeroText {
                padding-bottom: 13px;
              }
            }
          `}
        />

        <StickyNavigationBranded
          items={PageData.stickyNavigation}
          trackId="how-we-protect-you-stickynav"
        />

        <Section className="security-protecting-you">
          <ConfettiBackground>
            <FeaturesSlider
              id={PageData.stickyNavigation[0].anchor}
              heading={PageData.stickyNavigation[0].label}
              subheading="To keep our customers safe and secure, we utilise some of the best processes and technology available. Here are a few of the ways we keep you safe and secure."
              data={PageData.content.protectionFeatures}
              whiteTiles
              backgroundColor={COLOR.TRANSPARENT}
            />
          </ConfettiBackground>
        </Section>
        <HorizontalRule />

        <SectionHeading
          id={PageData.stickyNavigation[1].anchor}
          className="security-online-security"
        >
          {PageData.stickyNavigation[1].label}
        </SectionHeading>
        <HowToApply
          box1={
            <div>
              <BoxHeading>Mobile App Security</BoxHeading>
              <List>
                <ListItem>
                  Our mobile app enables you to use Face ID or fingerprint to
                  login for an extra layer of security.
                </ListItem>
                <ListItem>
                  We may ask you for further verification when you perform
                  certain activities such as entering a SMS code.
                </ListItem>
                <ListItem>
                  We keep our mobile app security up to date. Turn on automatic
                  updates to make sure you always have the latest version
                  available.
                </ListItem>
              </List>
            </div>
          }
          box2={
            <div>
              <BoxHeading>Latitude Service Centre Security</BoxHeading>
              <List>
                <ListItem>
                  We'll automatically log you out of your account if there's
                  been no activity for a certain period of time. It's designed
                  to protect your information and identity.
                </ListItem>
                <ListItem>
                  When registering your account or completing specific actions,
                  we send a verification SMS to the mobile number associated
                  with your account.
                </ListItem>
                <ListItem>
                  We ask you to set up a secure login by creating a username,
                  password, and security questions and answers in case you need
                  to reset your password.
                </ListItem>
                <ListItem>
                  After several unsuccessful login attempts, we'll temporarily
                  suspend or lock your online account.
                </ListItem>
              </List>
            </div>
          }
        />

        <ContactingLatitude
          anchor={PageData.stickyNavigation[2].anchor}
          heading={PageData.stickyNavigation[2].label}
        />
      </main>
    </Layout>
  );
};

export default HowWeProtectYou;

const SectionHeading = styled.h2`
  margin: 40px 0;
  color: #000;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: 72px;
    font-size: 32px;
    line-height: 40px;
  }
`;

const BoxHeading = styled.h3`
  color: #000;
  font-size: 22px;
  font-weight: 600;
  margin-top: 0;
`;

const ConfettiBackground = styled.div`
  @media (min-width: ${BREAKPOINT.SM}) {
    background-image: url(${imageConfettiLeft}), url(${imageConfettiRight});
    background-position: left center, right center;
    background-repeat: no-repeat, no-repeat;
    background-size: 229px 440px, 193px 440px;
  }
`;
